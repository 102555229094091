// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseAuthAPIUrl: 'https://auth.r1.rpost.net',
  azureSSOClientId: 'a58f8234-f671-4320-b595-68299fcab383',
  scopeId: '3a59807d-8410-405e-988e-adde4c2fe96f',
  googleSSOClientId: '27038867997-6073okjaag34jfgg5nu2uau867aa4rbf.apps.googleusercontent.com',
  googleProvider: 'Google',
  azureADProvider: 'AzureAD',
  rcapClientId: '89278687-d54e-4321-81af-9e0d5f2f8cda'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
